<template>
  <div class="app-conter">
    <!-- log图片 -->
    <div class="header">
      <img src="../../assets/img/logo.png" alt="" />
    </div>
    <!-- 登录信息 -->
    <div>
      <van-form>
        <van-field
          maxlength="12"
          class="fieldbox"
          v-model="loginData.name"
          label="|"
          label-width="2px"
          placeholder="请输入你的姓名"
          :left-icon="require('../../assets/img/yhm.png')"
        />
        <van-field
          maxlength="18"
          class="fieldbox"
          v-model="loginData.idCard"
          label="|"
          label-width="2px"
          placeholder="请输入你的身份证"
          :left-icon="require('../../assets/img/sfz.png')"
        />
        <van-field
          type="number"
          maxlength="11"
          class="fieldbox"
          v-model="loginData.phone"
          label="|"
          label-width="2px"
          placeholder="请输入你的手机号"
          :left-icon="require('../../assets/img/sjh.png')"
        />
        <div style="margin: 14px 20px; font-size: 12px">
          <van-checkbox
            v-model="checked"
            icon-size="14px"
            shape="square"
            checked-color="#A78A48"
            >已阅读并同意
            <span style="color: #0090ff">《服务协议》</span>
            和
            <span style="color: #0090ff">《隐私协议》</span>
          </van-checkbox>
        </div>

        <div style="margin: 40px 20px">
          <van-button
            style="border-radius: 8px"
            color="linear-gradient(to right, #BCA675, #A78A48)"
            type="primary"
            size="large"
            block
            @click="ViewLoans"
            >查看贷款</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { login } from "../../api/home/home";
import { setToken, setUserId } from "../../utils/LocalStorage";
export default {
  data() {
    return {
      // 登录信息
      loginData: {
        idCard: "",
        name: "",
        phone: "",
      },
      // 是否阅读服务协议和隐私协议
      checked: true,
    };
  },
  methods: {
    ViewLoans() {
      // 判断 是否填写完整
      for (const key in this.loginData) {
        if (!this.loginData[key]) {
          this.$toast.fail("请填写完整");
          return;
        }
      }
      // 判断 是否阅读服务协议和隐私协议
      if (this.checked) {
        login(this.loginData).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            // 存储token和id
            setToken(res.data.data.token);
            setUserId(res.data.data.id);
            this.$router.push("/home");
          } else {
            this.$toast(res.data.msg);
          }
        });
      } else {
        this.$toast("请先阅读服务协议和隐私协议");
      }
    },
  },
};
</script>

<style scoped>
.app-conter {
  width: 90%;
  margin: auto;
}
.header {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fieldbox {
  margin-top: 10px;
}
</style>
